import React from 'react'
import CV from '../../assets/resume-website.pdf'


export const CTA = () => {
  return (
    <div className='cta'>
        <a href={CV} target="_blank" rel="noopener noreferrer" className='btn'>Resume</a>
        <a href="#contact" className='btn btn-primary'>Contact Me</a>
    </div>
  )
}

export default CTA